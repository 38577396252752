import React, { FC } from 'react';
import { ICreditCardReviews } from './CreditCardReviews.def';
import PageHeader from '../../../PageHeader/PageHeader';
import HorizontalBrowser from '../../../HorizontalBrowser';

const CreditCardReviews: FC<ICreditCardReviews> = ({
  personalCreditCardsReviews,
  businessCreditCardsReviews,
  pageHeader,
}) => {
  const scrollerSettings = {
    defaultTilesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        tilesToShow: 3,
      },
      { breakpoint: 500, tilesToShow: 2 },
    ],
  };
  return (
    <div className="credit-card-reviews">
      {pageHeader && <PageHeader {...pageHeader} />}
      <div className="container">
        <HorizontalBrowser {...personalCreditCardsReviews} scrollerSettings={scrollerSettings} />
        <HorizontalBrowser
          {...businessCreditCardsReviews}
          tileClassName="topBusinessCreditCards"
          scrollerSettings={scrollerSettings}
        />
      </div>
    </div>
  );
};

export default CreditCardReviews;
