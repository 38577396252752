import React from 'react';
import { PageProps } from 'gatsby';

import Seo from '../../../components/Seo';
import CreditCardReviews from '../../../components/CreditCards/components/CreditCardReviews/CreditCardReviews';
import { getDefaultSEO } from '../../../utils';
import HTPageWrapper from '../../../wrappers/HTPageWrapper';

const ReviewsPage = ({ location }: PageProps) => {
  // TODO: move selection of card IDs to CMS
  const personalCardIds = [5, 2085, 205, 1983, 21336];
  const businessCardIds = [285059, 237595, 231162, 221656, 205767, 183935, 2991];
  const personalCreditCards = personalCardIds.map((cardId) => ({ cardId, variant: 'excerpt' }));
  const businessCreditCards = businessCardIds.map((cardId) => ({ cardId, variant: 'excerpt' }));
  const title = 'Credit Card Reviews';

  return (
    <HTPageWrapper category="Hub Page, Credit Cards, Reviews" title={title} location={location}>
      <CreditCardReviews
        pageHeader={{
          title: title,
          subTitle:
            'Explore detailed reviews from our experts, giving you all the pros and cons across a huge range of credit cards.',
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
            {
              link: '/credit-cards/',
              text: 'Credit Cards',
            },
            {
              link: '/credit-cards/reviews/',
              text: 'Credit Card Reviews',
            },
          ],
        }}
        personalCreditCardsReviews={{
          title: '',
          headline: 'Top Reviews',
          seeAllLink: {
            link: '/credit-cards/reviews/all/',
            text: 'See All Reviews',
          },
          cards: personalCreditCards,
        }}
        businessCreditCardsReviews={{
          title: 'Business Credit Card Reviews',
          headline: 'Top Reviews',
          seeAllLink: {
            link: '/credit-cards/reviews/all/',
            text: 'See All Reviews',
          },
          subtitle:
            'Our experts have analyzed the best business credit cards on the market to provide you with all the information you need to pick the one that is right for your business.',
          cards: businessCreditCards,
        }}
      />
    </HTPageWrapper>
  );
};

export default ReviewsPage;

export const Head = ({ location }: PageProps) => {
  const pageTitle = 'Credit Card Reviews from UpgradedPoints.com';
  const socialTitle = pageTitle;
  const ogDescription =
    "Explore comprehensive credit card reviews from our finance and travel points experts. We've done the analysis to make sure you select the right card for you.";
  const includeCollectionPageType = true;
  const extraBreadcrumbList = [
    { name: 'Credit Cards', slug: 'credit-cards' },
    { name: 'Credit Card Reviews', slug: 'reviews' },
  ];

  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  return <Seo title={pageTitle} wpSeo={seo} />;
};
